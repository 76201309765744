<template>
<div>
  <Header>
    <template #right-header-component>
      <div></div>
<!--      <ProfileDropDown/>-->
   </template>
  </Header>

  <div style="margin-top: 55px"  >
    <div class=" tw-ml-4 sm:tw-ml-12 mb-2 tw-pt-16">
      <button @click="goBack()" class="tw-flex">
        <ChevronLeftIcon/>
        <p class="tw-font-bold tw-text-base" >Back</p>
      </button>
    </div>
    <div class=" applyFlexCenter">
      <v-card flat class="tw-w-80 md:tw-w-96 tw-px-6 sm:tw-px-0">
        <h3 class="page-title">Upload Candidates</h3>
        <h4 class="subtitle mt-4 mb-8">Screen and manage an existing pool of candidates for later recruitments.</h4>

        <v-card class="px-1" flat v-model="showDragDiv">
          <div
              v-show="file === ''"
              :style="{ 'background-color': color }"
              class="drag-div tw-bg-enumBlue5"
              @dragleave="dragleave"
              @dragover="dragover"
              @drop="drop"
              id="drop"
          >
            <input
                id="assetsFieldHandle"
                ref="file"
                :accept="`${ext}`"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                name="fields[assetsFieldHandle][]"
                style="display: none"
                type="file"
                @click="onInputClick"
                @change="getFile()"

            />
            <label class="d-flex align-center justify-center upload-label flex-column cursor-pointer" for="assetsFieldHandle">
              <UploadIcon class="tw-text-enumGrey2"/>
              <span class="drag-drop mx-auto">
                    <p class="font-weight-bold tw-text-enumGrey2 my-2">Drag and drop or <span class="choose__file">choose file</span></p>
                    <p class="size__instruction tw-text-enumGrey3 d-block ">csv, xlsx recommended, max size 1MB</p>
                  </span>
            </label>
          </div>
        </v-card>


        <div v-if="file" class="file" style="cursor: pointer">
          <div class="d-flex justify-space-between pt-3 px-3">
            <v-icon> mdi-file-outline</v-icon>
            <span class="fileName" >{{shortenedTitle( file.name) }}</span>
            <v-icon @click="remove" id="remove">mdi-close</v-icon>
          </div>
        </div>

        <BaseButton :loading="loading" @click="uploadCSV()" class="mt-16" id="uploadData" width="145px" button-text="Upload Data"></BaseButton>
        <h4 class="re-qualification-text mt-5">Candidates would be sent an re-qualification email inviting them to take the assessments for the job opening again.</h4>
        <h4 class="help mt-16">Need Help?</h4>
        <h4 class="download-template mt-4 mb-6"><strong><a href="https://res.cloudinary.com/dcu4olk01/raw/upload/v1631658699/Assignments/sample.csv">Download template</a></strong> to guide you in uploading the applicant’s data in .csv document</h4>
      </v-card>
      <InvitesSentModal :message="dataDetails" :dialog="successDialog" @close="successDialog = false" id="closeDialog"/>

    </div>
  </div>
</div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {UploadIcon, ChevronLeftIcon} from "vue-feather-icons"
// import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import Header from "@/components/onboarding/reuseables/Header";
import {uploadEmailsToATalentPool} from "@/services/api/APIService"
import getSiteName from "@/services/utils";
import InvitesSentModal from "@/components/talentPool/InvitesSentModal";

export default {
name: "UploadCandidatesPage",
  components:{InvitesSentModal, BaseButton, Header, UploadIcon, ChevronLeftIcon},
  data(){
    return{
      ext:".csv, .xlsx",
      showDragDiv: true,
      file: "",
      loading: false,
      color: "",
      dataDetails: "",
      successDialog: false,
    }
  },
  methods:{
    onInputClick (event) {
      event.target.value = ''
    },
    goBack(){
      this.$router.push({name: 'AllCandidateInATalentPool',params:{talentPoolId: this.$route.params.talentPoolId}})
    },
    getFile(){
      this.file = this.$refs.file.files[0];
      console.log(this.file)
      const exts = ['xlsx', 'csv']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      console.log(fileExtension)
      if (!exts.includes(fileExtension)) {
        console.log('d')
        // this.$displaySnackbar({message: 'Only accepts Images', success: false})
        this.$displaySnackbar({message: 'Please upload file in recommended format', success: false})
        this.file = "";
        return
      }
      console.log(this.file)
      this.file = this.$refs.file.files[0];
      console.log(this.file)
      this.showDragDiv = false;
    },
    uploadCSV(){
      this.loading =true
      let site_name = getSiteName()
      let pool_id = this.$route.params.talentPoolId
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("site_name", site_name);
      formData.append("pool_id", pool_id);
      uploadEmailsToATalentPool(formData)
          .then(res =>{console.log(res)
            if(res.data.number_of_emails_added === 1){
              this.dataDetails = `1 email added to Talent Pool, ${res.data.number_of_emails_failed} failed.`
            }else  this.dataDetails = res.data.detail

            this.showDragDiv = false;
            this.file = "";
            this.loading = false
            this.successDialog = true
          })
          .catch(err =>{
            console.log(err)
            this.$displaySnackbar({message: err.response.data.detail, success: false})
            this.loading = false})
    },
    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 20)
    },
    onChange() {
      this.file = this.$refs.file.files[0];
      this.showDragDiv = false;
    },
    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },
    dragleave() {
      // Clean up
      this.color = "#FBFDFE";
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      this.color = "#FBFDFE";
    },
    remove() {
      this.file = "";
      this.showDragDiv = true;
    }
  }
}
</script>

<style scoped>
.page-title{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
h3,h4{
  font-family: DM Sans;
  font-style: normal;
}
.subtitle{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.re-qualification-text{
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #9CABB5;
}
.help{
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
.download-template{
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
.download-template strong{
  color: #008EEF;
}

p{
  font-family: "DM Sans",serif;
}
.drag-div {
  border: 1px dashed #008EEF;
  box-sizing: border-box;
  border-radius: 10px;
  height: 118px;
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.file {
  border-radius: 8px;
  width: 250px;
  height: 50px;
  border: 1px solid #eaeaea;
  margin-bottom: 28px;
}

.upload-label {
  height: 100%;
  cursor: pointer;
}

.choose__file {
  color: #008EEF;
  font-family: "DM Sans",serif;
}

.size__instruction {
  font-size: 11px;
}
.fileName{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

</style>