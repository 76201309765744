<template>
  <v-dialog v-model="dialog" max-width="400" class="rounded-lg" @click:outside="$emit('close', moveToCandidateList())">
    <v-card width="400" class="pa-2">
      <div class="d-flex justify-end ">
        <v-btn text @click="dialog = false, moveToCandidateList()" id="invite_sent_close_btn" data-testid="cancel-modal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div  class="d-flex flex-column divHeight justify-center align-center">

        <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="40" cy="40.834" r="40" fill="#28E17E"/>
          <path d="M53.3327 27.5L38.666 42.1667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M53.3327 27.5L43.9993 54.1667L38.666 42.1667L26.666 36.8333L53.3327 27.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <h3 class="mt-4 sent">Invites Sent!</h3>
        <p class="mt-4 message">{{ message }}</p>

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
name: "InvitesSentModal",
  props: {
    dialog: Boolean,
    message: String
  },
  methods:{
    moveToCandidateList(){
      this.$router.push({name: 'AllCandidateInATalentPool', params:{talentPoolId: this.$route.params.talentPoolId}})
    }
  }
}
</script>

<style scoped>
.sent{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.divHeight{
  height: 270px;
}
.message{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #475661;
}
</style>